<template>
	<div class="page-wrapper">

        <!-- ******Header****** -->
        <header id="header" class="header container-fluid ">
				<a href="javascript:" class="return-to-top"><span class="ti-angle-up"></span></a>
            <div class="row" >
                <div class="col-md-6">

											<router-link to="/" id="img1">
													<!-- <p v-html="this.info.data.data.guide.guide_logo"></p> -->
													<b-img alt="Vue logo" :src="require('../assets/TaxSlip-Logo2.png')" class="img-fluid "/>
											</router-link>
											<span style="margin-top:30px;">
												<a href="/">Home</a>
												<a class="active"> / {{this.info.data.data.guide.guide_title.title}} </a>

											</span>

            </div>
					</div>
        </header>

        <div class="doc-wrapper">

            <div class="container">
                <div id="doc-header" class="doc-header text-center">

									<h3 class="doc-title"><i class="icon fa fa-paper-plane mt-5"></i>{{this.info.data.data.guide.guide_title.title}}</h3> {{message}}

										<div class="meta"><i class="far fa-clock"></i> {{this.info.data.data.guide.guide_title.title_tagline}}</div>
									<!-- <h1>{{$route.params.userId}}</h1> -->
								</div>

								<div class="doc-body row">

    								<div class="doc-content col-md-10 col-12 order-1 col-xs-12 col-sm-12 guide"  >
											<div class="content-inner" v-for="sections in info.data.data.guide.sections">
												<section  v-bind:id="section.heading" class="doc-section" v-for="section in sections ">
													<h2 class="section-title" >{{section.heading }}</h2>

													<p  v-html="section.description"></p>


													<!-- ////// if sub Headings Come/////// -->

													<div v-bind:id="sub.heading"  class="section-block"  v-if="section.sub_heading" v-for="sub in section.sub_heading" >
														<h3 class="block-title" >{{sub.heading}}</h3>
														<hr />
														<p v-html="sub.description"></p>

														<div class="callout-block callout-success" v-if="sub.tips">
																<div class="icon-holder">
																		<i class="fa fa-thumbs-up"></i>
																</div><!--//icon-holder-->
																<div class="content" v-html="sub.tips">
																</div>
														</div>
													</div>




													<!-- ////// if Tips come then show this block/////// -->
														<div class="callout-block callout-success" v-if="section.tips">
																<div class="icon-holder">
																		<i class="fa fa-thumbs-up"></i>
																</div><!--//icon-holder-->
																<div class="content" v-html="section.tips">
																</div>
													</div>
											</section>
									</div>
								</div>


								<!-- //Side Menu bar -->
								<div class="doc-sidebar col-md-2 col-12 order-0 sidenav d-block d-none ScrollStyle  ">
											<!-- <div class="	doc-sidebar col-md-3 col-12 order-0 d-none d-md-flex sidenav"> -->

                        <div id="doc-nav" class="doc-nav"  v-for="sections in info.data.data.guide.sections">
													<nav id="doc-menu" class="nav doc-menu flex-column sticky"  v-for="section in sections">
														<a class="nav-link scrollto" :href ="'#' + section.heading">{{section.heading  }}</a>
														<nav class="doc-sub-menu nav flex-column" v-if="section.sub_heading" v-for="sub in section.sub_heading ">
															<a class="nav-link scrollto" :href="'#' + sub.heading">{{sub.heading}}</a>
														</nav>
													</nav>
												</div>

                </div><!--//doc-sidebar-->

          </div><!--//doc-body-->
      </div><!--//container-->
  </div><!--//doc-wrapper-->
    <footer class="doc-footer text-center">
        <div class="container">
            <!--/* This template is released under the Creative Commons Attribution 3.0 License. Please keep the attribution link below when using for your own project. Thank you for your support. :) If you'd like to use the template without the attribution, you can buy the commercial license via our website: themes.3rdwavemedia.com */-->
            <small class="copyright">Designed by <a href="http://taxslips.ca/" target="_blank">TaxSlips</a></small>

        </div><!--//container-->
    </footer>


</div><!--//page-wrapper-->
</template>


<script>
import sidebar from './Sidebar.vue'
import { Affix } from 'vue-affix';
import { mapState } from 'vuex'
export default{
  name: 'guide',
	components: {
    Affix,
		'app-sidebar': sidebar,

  },
	data (){

		return {
			info: null,
			inffo:true,
			message:'',
			guide:{
				guide_title:''
			},
			flag:2,
			title:'hellofdsff',
			section :{
				heading:''
			}
		}
	},


  mounted(){

		var flag =	this.$route.params.slug

		 axios
		.get(this.BASE_URL+'taxslip_api/api_guide/list',{"params": {token: this.token, flag_id:flag}})
		// .then(response =>console.log('response=>'+response))
		// .then(response => ()).
		.then(response =>(this.info = response

		))
		.catch(error => {
			this.message =error.response.data
		});

		this.$router.push({name:'guide', params: { slug : this.info.data.data.guide.guide_title.guide_url }} );

    $(document).ready(function(){

        $("a[href^='#']").click(function(e) {
          e.preventDefault();
          var position = $($(this).attr("href")).offset().top;
          var position = position - 10;
          $("body, html").animate({
            scrollTop: position
        } ,1000 );
      });

			$(window).scroll(function(){
			  var scroll = $(window).scrollTop();
			  if (scroll > 100) {
			    $('.return-to-top').fadeIn(200);
			  }else{
			    $('.return-to-top').fadeOut(200);
			  }
			  });
			//Back to top Function\
			$('.return-to-top').click(function() {      // When arrow is clicked
			  $('body,html').animate({
			      scrollTop : 0                       // Scroll to top of body
			  }, 500);
			});
    });
},



computed: mapState([
'taxslipCMS'
]),
};
</script>
<style>
.header {
		border-top:5px solid #ef8410;
		background: #494d55;
		color: rgba(255, 255, 255, 0.85);

}
.nav  > a:hover{
    color:#ef8410;
		/* font-size: 150%; */
}

.ScrollStyle
{
    max-height: 80%;
    overflow-y: auto;
		float: left;
		margin: 0 10px;
}
.ScrollStyle::-webkit-scrollbar {
  width: 5px;
}
.ScrollStyle::-webkit-scrollbar-track {
  background: #ef8410;
}

.ScrollStyle::-webkit-scrollbar-thumb {
  background: #ef8410;
}
.doc-content{
	text-align:left;
	/* background-color:orange; */
	/* margin-right:90%; */
	margin-left:20%; /* Same as the width of the sidenav */
 /* Increased text to enable scrolling */
	padding: 20px 10px;
	margin-bottom:50px;

}


.sidenav {
  height: 100%;
  position: fixed;
  top: 10%;
  left: 0;
	text-align:left;
  overflow-x: hidden;
  padding-top: 45px;
	/* padding-left: 70px; */
	margin-left:8.5%;
	/* background-color:gray; */

	}

	@media screen and (max-width: 700px) {
	 .sidenav {
	   width:0;
		 /* position: absolute; */
	  }

	}

.breadcrumb {
background: none;
margin-bottom: 0;
padding: 0px 0px;
}
.breadcrumb li{
   color: rgba(255, 255, 255, 0.85);
}
.doc-menu .nav-link {
    margin-bottom: 5px;
    display: block;
    padding: 5px 15px;
    color: #616670;
}
.doc-sub-menu .nav-link {
    margin-bottom: 10px;
    font-size: 14px;
    display: block;
    color: #616670;
    padding: 0;
    padding-left: 34px !important;
    background: none;
}
.doc-title {
    padding-top:20px 0px;
    color:  #ef8410;
}
.doc-header{
    padding-top:40px 20px;
		margin-top:40px;
}
.doc-body{
    margin-top:20px;
    line-height: 2;
}
ol,ul{
    padding-left:15px !important;
}

.callout-block {
  padding: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  margin-bottom: 30px;
}

.callout-block a {
  color: rgba(0, 0, 0, 0.55) !important;
}

.callout-block a:hover {
  color: rgba(0, 0, 0, 0.65) !important;
}

.callout-block .icon-holder {
  font-size: 30px;
  position: absolute;
  left: 30px;
  top: 30px;
  color: rgba(0, 0, 0, 0.25);
}

.callout-block .content {
  margin-left: 60px;
}
.guide img {
max-width: 100%;
height: auto;
}
.callout-block .content p:last-child {
  margin-bottom: 0;
}
.content h4{
	/* color:black; */
}
.callout-block .callout-title {
  margin-top: 0;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.65);
}

.callout-info {
  background: #58bbee;
  color: #fff;
}

.callout-success {
  background: #75c181;
  color: #fff;
}

.callout-warning {
  background: #F88C30;
  color: #fff;
}

.callout-danger {
  background: #f77b6b;
  color: #fff;
}
.doc-section .section-title {
    font-size: 26px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    padding-top: 90px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(215, 214, 214);
}
.section-block{
	  margin-top: 10px;
		padding-top:80px;
}
.section-block {
    margin-top: 10px;
    padding-top: 0;
}
.doc-section h3 {
    padding-top:70px;
    font-size: 20px;
    font-weight: bold;
}
.doc-footer {
    background: #26282c;
    color: rgba(255, 255, 255, 0.6);
    padding: 15px 0;
    height: 70px;
		z-index: 999999;
    font-size: 21px;
}
doc-sub-menu .nav-link:first-child {
    padding-top: 5px;
}


h5{
    margin-top:20px;
}
a{
    color: #ef8410;
}
/***********************
*	Scroll back to top
************************/
.return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #ef8410;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 999;
}
.return-to-top span {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
.return-to-top:hover span {
    color: #fff;
    top: 5px;
}


#header {
  overflow: hidden;
  position: fixed;
  top: 0;
	z-index: 99999;
  width: 100%;
	/* height:8%; */

}


#img1{

 margin-left:15%;
	/* padding-left:120px; */
}
.branding{
	/* padding-right:900px; */
}


</style>
